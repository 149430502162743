import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DatasetIcon from "@mui/icons-material/Dataset";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocalMallIcon from '@mui/icons-material/LocalMall';




export const BrandData = [
  {
    title: "Dashboard",
    path: "/brand/",
    icon: <GridViewIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },
  {
    title: "My Campaigns",
    path: "/brand/campaign",
    icon: <CampaignIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },
  {
    title: "Orders",
    path: "/brand/order",
    icon: <LocalMallIcon/>,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },

 
 
];
