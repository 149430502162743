import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AddShoppingCartSharpIcon from '@mui/icons-material/AddShoppingCartSharp';



export const SidebarDataOperations = [
  {
    title: "Dashboard",
    path: "/influencer/",
    icon: <GridViewIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },

  {
    title: "My Orders",
    path: "/influencer/order",
    icon: <LocalMallIcon/>,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },
  {
    title: "Add Order",
    path: "/influencer/order/add",
    icon: <AddShoppingCartSharpIcon/>,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },
  {
    title: "Add Review",
    path: "/influencer/review/add",
    icon: <RateReviewIcon/>,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },

 
 
];
