import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";

import HistoryIcon from "@mui/icons-material/History";
import ShortingTable from "../tabel/ShortingTabel";

export default function InfluencerNoteHistory({
  historyDrawerOpen,
  setHistoryDrawerOpen,
  rowData,
}) {
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setHistoryDrawerOpen(open);
  };

  const realData =
    rowData?.adminNoteHistory &&
    rowData?.adminNoteHistory.map((item, index) => {
      let status = item.influencerStatus?.status;
      if (status === "Reviewed") {
        status = "Review Approval Pending";
      } else if (rowData?.reimbursed === "No" && status === "Reimbursed") {
        status = "Closed";
      }
      return {
        sno: index + 1,
        adminnote: item.adminNote && item.adminNote,
        status: status,
        updatedat:
          item.updatedAt &&
          new Date(item.updatedAt).toISOString().split("T")[0],
        updatedby: item.updatedBy?.name,
      };
    });
  const list = (
    <Box
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>Notes History</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setHistoryDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            <Tooltip placement="top" title="Note History">
              <HistoryIcon
                style={{
                  width: "30px",
                  height: "35px",
                  color: "#163e6e",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   setShowHistory(true);
                //   setShowDetails(false);
                // }}
              />
            </Tooltip>
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              <>
                <ShortingTable
                  columns={columns}
                  // loading={activeCampaignDetailsLoading}
                  data={realData}
                />

                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              </>
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={historyDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              overflow: "auto",
              width: "50%",
              "@media (max-width: 600px)": {
                width: "90%",
              },
            },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
